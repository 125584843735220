// /* You can add global styles to this file, and also import other style files */
@import './central-login-ui-theme.scss';

body {
  background: linear-gradient(
      to bottom,
      $_top-gradient 32%,
      $_bottom-gradient
    ) fixed,
    var(--background-image, url('/assets/form-background.png'));

  background: linear-gradient(
      to bottom,
      rgba(var(--top-gradient), 1) 32%,
      rgba(var(--bottom-gradient), 1)
    ) fixed,
    var(--background-image, url('/assets/form-background.png'));
  background-blend-mode: multiply;
}

.app-section-container {
  height: calc(100% - 60px);
  overflow-y: auto;
  padding-bottom: 50px;
}
.ruf-card-panel.mat-card {
  border-radius: 0.125rem;
}
.user-container {
  height: calc(100% - 97px);
  overflow-y: auto;
  padding-bottom: 50px;
}

/* common */
@import 'node_modules/@ruf/theming/styles/theming/theming';
@import 'node_modules/@ruf/shell/src/card/card.theme';
@import 'node_modules/@ruf/shell/src/banner/banner.theme';
@import 'node_modules/@ruf/material/src/button/button.theme';

// a convenience mixin that aggregates all of your component theme mixins
@mixin example-theme($theme) {
  @include ruf-card-theme($theme);
}

@mixin example-typography($config) {
  cls-select {
  }
}


// a convenience mixin that aggregates all of your component theme mixins
@mixin example-theme($theme) {
  @include ruf-banner-theme($theme);
}

@mixin example-typography($config) {
  // $config: map-merge(theming.ruf-get-default-typography-typography(), (
  //   font-family : 'Roboto'
  // ));

  $config: ruf-typography-config(
    (
      font-family: 'Roboto'
    ));

  @include app-typography($config);
}

@media screen and (max-width: 4500px) and (min-width: 0px) {


  .card-example-canvas ruf-card {
    margin: 0% 33%;
    max-width: 540px;
    width: auto;
  }

  ruf-loginflow {
    // display: flex;
    justify-content: center;
    // padding: 4%;
  }

  .footer-div {
    //margin: 10% 30%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    color: white;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .footer {
    width: 100%;
    /* UI Properties */
    position: fixed;
    bottom: 0;
    font: normal normal normal 12px/16px Roboto, Arial, sans-serif;
    letter-spacing: 0px;
    color: gray;
    background: rgba(204,204,204);
    opacity: 1;
    padding: 0.688rem 0 0.688rem 0;
  }

  .footer-registration-mark {
    height: 100%;
    width: 50%;
    float: left;
    text-align: left;
    padding-left: 10px;
    font: normal normal normal 13px/16px Roboto, Arial, sans-serif;
    letter-spacing: 0px;
    color: gray;
    opacity: 1;
  }

  .footer-version-data {
    height: 100%;
    width: 50%;
    /* UI Properties */
    float: right;
    text-align: right;
    padding-right: 10px;
    font: normal normal normal 13px/16px Roboto, Arial, sans-serif;
    letter-spacing: 0px;
    color: gray;
    opacity: 1;
  }

  .clp-canvas {
    background: transparent url('/assets/clp_newBackground.png')  0% 0% no-repeat padding-box;
    background-size: cover;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .product-branding {
    display: flex;
    justify-content: center;
    margin: 32px 0 34px 0;
    z-index: 1;
    align-items: flex-end;

    .logo {
      height: 50px;
      width: 130px;
      background: url(/assets/fis-white.svg) no-repeat;
      background-position-x: right;
      background-position-y: center;
    }
  }

  .formField {
    width: 100%;
    padding: 0px 10px;
  }

  .hide-element {
    visibility: hidden;
  }

  .buttonSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    button {
      //Keep button center when spinner is shown.
      &:not(:last-child) {
        margin-left: calc(1rem + 25px);
      }
    }
    mat-spinner {
      display: inline;
      box-sizing: content-box;
      align-self: center;
      width: 25px !important;
      height: 25px !important;
      margin-left: 1rem;
      padding: 1px;
    }
  }

  // @include if-mq(gt-xs) {
  //   .buttonSection {
  //     flex-direction: row;
  //   }
  // }

   .btnProceed {
    width: 150px;
    height: 70px;
    text-align: center;
    padding-right: 50px;
    color: #ffffff;
    background: #00565b;
    border: 1px solid #00565b;
    border-radius: 3px;
    font-weight: normal;
    font-size: large;
  }


  .statusBarIcon {
    margin-top: 6px;
  }
  .errStatusBar {
    color: #464646;
    font-family: Roboto, Arial, sans-serif;
    font-size: 14px;
    margin-top: 6px;
    white-space: normal;
  }

  .ruf-card-panel.mat-card .ruf-card-title, .ruf-card-panel.mat-card .ruf-card-metric {
    font-family: Roboto, Arial, sans-serif;
    font-size: 20px;
    font-weight: bold;
  }

// hide recaptcha badge
.grecaptcha-badge {
  visibility: hidden;
}

// only show recaptcha badge on needed pages
body.recaptcha {
  .grecaptcha-badge {
    visibility: visible;
  }
}

  }
