@import '../../variables.scss';

@mixin clp-login-theme-override {
  .submit:not([disabled]),
  material-button-widget button:not([disabled]) {
    min-width: 100px;

    // FIXME: default theme should be fixed to prevent having to do this
    background-color: $_primary !important;
    background-color: var(--primary) !important;
    color: $_primary-contrast !important;
    color: var(--primary-contrast) !important;
    border-color: $_primary !important;
    border-color: var(--primary) !important;

    &:hover,
    &.cdk-focused.cdk-keyboard-focused {
      // FIXME: default theme should be fixed to prevent having to do this
      background-color: transparent !important;
      color: $_primary !important;
      color: var(--primary) !important;
      border-color: $_primary !important;
      border-color: var(--primary) !important;
    }
  }

  .secondary {
    // FIXME: default theme should be fixed to prevent having to do this
    color: $_primary !important;
    color: var(--primary) !important;
    border-color: $_primary !important;
    border-color: var(--primary) !important;

    &:hover,
    &.cdk-focused.cdk-keyboard-focused {
      // FIXME: default theme should be fixed to prevent having to do this
      background-color: $_primary !important;
      background-color: var(--primary) !important;
      color: $_primary-contrast !important;
      color: var(--primary-contrast) !important;
      border-color: $_primary !important;
      border-color: var(--primary) !important;
    }
  }
}
