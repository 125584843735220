@import 'node_modules/@ruf/theming/styles/theming/theming';

@import './login.common';

// a convenience mixin that aggregates all of your component theme mixins
@mixin clp-login-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);

  clp-login {
    .clp-legal-links {
      a {
        color: ruf-color($foreground, text);
        border-right-color: ruf-color($foreground, border);
      }
    }
    .mat-error {
      color: ruf-color($foreground, text);
    }
  }
}

@mixin clp-login-typography($config) {
  clp-message-container {
    .message {
      line-height: 28px;
    }
  }

  clp-login {
    .success-message-container,
    .error-message-container {
      mat-icon {
        font-size: 75px;
      }
    }

    .sd-value {
      font-weight: bold;
    }

    .clp-legal-links {
      a {
        font-weight: bold;
      }
    }

    message-widget {
      h3 {
        font-family: ruf-font-family($config, title);
      }
    }
  }
  .snackbar {
    font-family: 'Roboto';//'Source Sans Pro', Aria, sans-serif;
    font-size: 14px;
    font-weight: bold;
  }
}
