/* Importing the prebuilt color theme called "fis-default" */
@import '~@ruf/prebuilt-themes/fis-gold';
// @import '~@ruf/prebuilt-themes/fis-default';

/* Importing the prebuilt color theme called "fis-default" */
// @import '~@ruf/prebuilt-themes/fis-default-css-vars';
// Importing the RUF shell structural styles
@import '~@ruf/shell/styles/index';

@import './all-theme';


@include app-all-theme($theme, $typography-config);

// @import 'node_modules/@wrux/wrux-json-schema-form/theming/styles/_styles.theme.scss';
// @include wrux-forms-layout();
// $config: ruf-get-default-typography-config();

@import '~@ruf/widgets/styles/_all-theme.scss';

@include ruf-loading-indicator-theme($theme);

